import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 创建Store实例
const store = new Vuex.Store({
  // 存储状态值
  state: {
    count: 1,
    isPad: true,
    userInfo: null,
    teacher_id: 0,
    roomInfo: null,
    webSocketMsg: '',
    time_id: '',
    give_up: '',
    is_super: false,
  },
  // 状态值的改变方法,操作状态值
  // 提交mutations是更改Vuex状态的唯一方法
  mutations: {
    // eslint-disable-next-line no-unused-vars
    updateGiveUp(state, date) {
      state.give_up = date;
    },
    SET_WS_MSG(state, date) {
      state.webSocketMsg = date;
    },
    updateIsSuper(state, date) {
      state.is_super = date;
    },
    updateTimeId(state, date) {
      state.time_id = date;
    },
    updateTeacher(state, date) {
      state.teacher_id = date;
    },
    updateRoom(state, date) {
      state.roomInfo = date;
    },
    updateUserInfo(state, date) {
      state.userInfo = date;
    },
    updateEquipment(state, date) {
      state.isPad = date;
    },
    increment(state) {
      state.count = 1;
    },
    decrement(state) {
      state.count = 1;
    },
  },
  // 在store中定义getters（可以认为是store的计算属性）。Getters接收state作为其第一个函数
  getters: {
    webSocketMsg: state => state.webSocketMsg,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    updateGiveUp(context, payload) {
      context.commit('updateGiveUp', payload);
    },
  },
});
// 要改变状态值只能通过提交mutations来完成
export default store;
