<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2022-03-29 16:13:06
-->
<template>
  <div class="rtc-container">
    <!-- <canvas id="thumsCanvas" width="200" height="400" style="width:100px;height:200px"></canvas> -->
    <!-- <canvas id="thumsCanvas" width="200" height="400" style="width:100px;height:200px"></canvas> -->
    <!-- <div class="top-bar">
      <div class="btn-to" @click="onBack">返回</div>
    </div> -->
    <img src="../assets/image/6.png" alt="" class="btm">
    <div class="class_info" v-if="courseInfo">
      <div class="flote">
        <div class="title">
          <img src="../assets/image/icon1.png" alt="">
          {{courseInfo.course_name}}
        </div>
      </div>
      <div class="flote">
        <div class="name">
          <img src="../assets/image/icon2.png" alt="">
          {{courseInfo.teacher_name}}老师</div>
      </div>
      <div class="flote">
         <div class="date"><img src="../assets/image/icon3.png" alt="">{{courseInfo.beginTime}} - {{courseInfo.endTime}}</div>
      </div>

    </div>
    <div class="videos" >
      <div class="left">
        <!-- 老师分享视频 -->
        <div class="remote-container teacher-screen" v-if="teacherScreen">
          <div
            :key="(teacherScreen.getUserId()+'screen')"
            :id="(teacherScreen.getUserId()+'screen')"
            class="remote-stream-container">
          </div>
        </div>
        <!-- 老师主视频 -->
        <div class="remote-container teacher-video" :class="teacherScreen?'teacher-video-pos':''" v-if="teacherVideo">
          <div
            :key="teacherVideo.getUserId()+'live'"
            :id="teacherVideo.getUserId()+'live'"
            class="remote-stream-container">
          </div>
        </div>

      </div>
      <div class="praise_bubble" id="praise_bubble" v-show="teacherVideo"></div>
       <div class="zan-pos"  v-show="teacherVideo&&!$store.state.is_super">
            <img src="../assets/image/xin.png" alt="" class="zan" :class="scale?'zan2':''" @click="sendAddPraise">
            <div>鼓励下小宝贝吧</div>
            <div class="num">{{$store.state.give_up}}</div>
        </div>
    </div>
  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import shareRtc from  './mixins/share-rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
// import  thumbsUpAni  from './mixins/canvas.js';
// eslint-disable-next-line no-debugger


export default {
  name: 'compRoom',
  mixins: [rtc, shareRtc],
  props: {
    room_info: Object,
    teacher_id: Number,
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      isPad: true,
      courseInfo: '',
      praiseBubble: null,
      timer: null,
      scale: false,
      time_id: this.$store.state.time_id,
    };
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isEnLang() {
      return this.$i18n.locale === 'en';
    },
    showInviteLink() {
      return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink;
    },
    getWsMsg() {
      return this.$store.state.webSocketMsg;
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
    getWsMsg: {
      handler(newVal) {
        console.log(newVal);
        // eslint-disable-next-line no-debugger
        // debugger;
        const newMsg = JSON.parse(newVal.ws);
        switch (newMsg.type) {
          case 'give_up':
            this.$store.dispatch('updateGiveUp', newMsg.num);
            this.addPraise();
            break;
          case 'course_status':
            this.$dialog.alert({
              title: '温馨提示',
              message: '当前课程已结束，即将退出',
            }).then(() => {
              // on close
              this.$router.go(-1);
            });
            break;

          default:
            break;
        }
        console.log(newMsg);
      },
    },
  },
  created() {
    this.courseInfo = JSON.parse(localStorage.getItem('courseInfo'));
  },
  // methods: {
  //   handleValueChange(value, key) {
  //     this[key] = value;
  //   },
  // },
  mounted() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.praiseBubble = document.getElementById('praise_bubble');
    // this.timer = setInterval(() => {
    //   this.addPraise();
    // }, 600);
    // eslint-disable-next-line no-debugger
    // debugger;
    if (width / height > 1.4) {
    //   alert('手机');
      this.isPad = false;
      this.$store.commit('updateEquipment', false);
    } else {
      this.isPad = true;
      this.$store.commit('updateEquipment', true);
    //   alert('ipad');
    }
    this.handleJoinRoom();
  },
  destroyed() {
    this.leave();
    // clearInterval(this.timer);
    if (!this.$store.state.is_super) {
      this.$socketApi.webSocketClose();
    }
    sessionStorage.removeItem('token');
  },
  methods: {
    addPraise() {
      if (this.scaletime) {
        clearTimeout(this.scaletime);
      }
      this.scale = true;

      this.scaletime = setTimeout(() => {
        this.scale = false;
      }, 100);
      const b = Math.floor(Math.random() * 6) + 1;
      const bl = Math.floor(Math.random() * 11) + 1; // bl1~bl11

      const d = document.createElement('div');
      d.className = `bubble b${b} bl${bl}`;
      d.dataset.t = String(Date.now());
      this.praiseBubble.appendChild(d);
      // const num = this.$store.state.give_up + 1;
      // eslint-disable-next-line no-debugger
      // this.$store.dispatch('updateGiveUp', num);
      // const that = this;
      // this.$socketApi.webSocketSend({
      //   time_id: this.time_id,
      //   type: 'give_up',
      // });
    },
    sendAddPraise() {
      this.$socketApi.webSocketSend({
        time_id: this.time_id,
        type: 'give_up',
      });
    },
    onBack() {
      this.$router.go(-1);
      this.leave();
    },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, secretKey, roomId } = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert(this.$t('Please enter sdkAppId and secretKey'));
          return;
        }
        if (!this.userId || !this.roomId) {
          alert(this.$t('Please enter userId and roomId'));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert(this.$t('Please reacquire the invitation link'));
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      // await this.initLocalStream();
      // await this.playLocalStream();
      // await this.publish();
      this.generateInviteLink();
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.leave();
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      this.generateInviteLink();
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog() {
      if (!this.isHostMode) {
        return;
      }
      // this.logList.push({
      //   type: 'success',
      //   log,
      // });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog() {
      if (!this.isHostMode) {
        return;
      }
      // this.logList.push({
      //   type: 'failed',
      //   log,
      // });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
  },
};
</script>

<style lang="scss" >
#app{
  padding-bottom: 0;
}
.btm{
  position: fixed;
  width: 60vw;
  left: 20vw;
  bottom: 6vh;
}
.class_info{
  padding: 2rem 2vw;
  .flote{
   overflow: hidden;
   margin-bottom: 0.8rem;
  }
  // .flote2{
  //   height: 3rem;
  // }
  .title{
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    background: rgba(255, 255, 255, 0.5);
    padding: 0.4rem 0;
    padding-left: 0.4rem;
    padding-right: 1.3rem;
    border-radius: 2rem;
    color: #fa6c7c;
    img{
      width: 3rem;
      // margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }
    float: left;
  }
  .name{
    color: #666;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    padding: 0.4rem 0;
    padding-left: 0.4rem;
    padding-right: 1.3rem;
    border-radius: 2rem;
    float: left;

    img{
      width: 2rem;
      margin-right: 0.5rem;
      //  margin-bottom: 0.5rem;
    }
  }
  .date{
     color: #666;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    padding: 0.4rem 0;
    padding-left: 0.4rem;
    padding-right: 1.3rem;
    border-radius: 2rem;
    float: left;
    img{
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
}
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }


  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 480px;
      height: 360px;
      position: relative;
      flex-shrink: 0;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 20vh;
      height: 15vh;
      overflow: hidden;
    border-radius: 0.4rem;
    }
  }

  .remote-container {
    width: 100%;
    height: 100%;
    // margin-top: 10px;
    // display: flex;
    // flex-wrap: wrap;
    .remote-stream-container {
      // width: 320px;
      // height: 240px;
      width: 100%;
      height: 100%;
      // margin: 0 10px 10px 0;
    }
  }
  .remain-container{
    width: 20vh;
      height: 15vh;
      overflow: hidden;
    border-radius: 0.4rem;
    margin-top: 0.2rem;
  }
}
.videos{
  // height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;
  box-sizing: border-box;
  position: relative;
  .teacher-video{

    position: absolute;
    right: 0;
    bottom: 0;
    // overflow: hidden;
    // .remote-stream-container{
      // height: 60vh;
      // width: 106vh;
    // }
  }
  .teacher-video-pos{
    position: absolute;
    right: 0.7rem;
    bottom:  0.7rem;
    height: 15vh;
    width: 20vh;
    z-index: 100;
    border-radius: 0.4rem;
    overflow: hidden;
  }
  .left{
    height: 72vw;
    width: 96vw;
    position: relative;
    overflow: hidden;
    border-radius: 3vw;
    background: url('../assets/image/5.png') #fff no-repeat center center;
    background-size: 55vw;
  }
}
.videos-phone{
  display: flex;
  padding: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .left{
    height: 85vh;
    width:151vh;
    position: relative;
    overflow: hidden;
    border-radius: 0.4rem;
    .teacher-video{
      height: 85vh;
      width:151vh;
      position: relative;
      overflow: hidden;
      border-radius: 3vh;
    }
    .teacher-video-pos{
      position: absolute;
      right: 2vh;
      bottom: 2vh;
        height: 22.5vh;
        width: 30vh;
    }
  }
  .right{
    height: 85vh;
    display: flex;
    align-items: flex-start;
    margin-left: 5vh;
  }
  .btn-to{
        border-radius: 0.1rem;
        background: #f7718a;
        color: #fff;
        text-align: center;
        padding: 0 5vh;
        height: 8vh;
        line-height:8vh;
        font-size: 1rem;
    }
}
.localStream-phone{
  position: fixed;
  top: 0;
  right: 0;
}
.top-bar{
  display: flex;
  justify-content: space-between;
  padding: 2rem 2vw 0;
  position: fixed;
  top: 0;
  width: 100vw;
  .teacher{
    font-size: 0.5rem;
    display: flex;
    align-items: center;
    img{
      width: 1rem;
      margin-right: 0.2rem;
    }
  }
  .class-name{
    font-size: 1rem;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top,#fb8a9c,#ee5b77);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // box-shadow: 0 0 3px #fff;
  }
  .btn-to{
        border-radius: 0.1rem;
        background: #f7718a;
        color: #fff;
        text-align: center;
        padding: 0.3rem 1rem;

        font-size: 1.5rem;
        border-radius: 0.3rem;
    }
}

body {
  --bubble_time: 3s;
  --bubble_scale: 0.8s;
}
.zan-pos{
  position: absolute;
  bottom: -19vw;
  right: 4vw;
  z-index: 1000;
  color: #eb3355;
  .num{
    position: absolute;
    top: -0.5rem;
    background: #fff;
    width: 4rem;
    height: 1.3rem;
    left: 50%;
    margin-left: -2rem;
    line-height: 1.3rem;
    border-radius: 1rem;
    text-align: center;
  }
}
.zan{
  width: 4rem;
  display: block;
  margin: 0 auto 0.5rem;
  // transform: scale(1);
  // transition: transform 1s ;
  // -webkit-transition: -webkit-transform 1s;
}
.zan2{
   transform: scale(0.8);
  // transition: transform 1s ;
  // -webkit-transition: -webkit-transform 1s;
}
.praise_bubble {
  width: 100px;
  height: 200px;
  position: absolute;
  right: 0;
  z-index: 100;
  bottom: -2vw;
  // background-color: #f4f4f4;
}
.bubble {
  position: absolute;
  width: 3rem;
  height: 3rem;
  left: 30px;
  bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100%;
  transform-origin: bottom;
}
.b1 {
  background-image: url('../assets/image/l1.png');
  // 可以使用雪碧图
  // background-position: -42px -107px;
  // background-size: 188.5px 147px;
}
.b2 {
  background-image: url('../assets/image/l2.png');
  // background-position: -84px -107px;
  // background-size: 188.5px 147px;
}
.b3 {
  background-image: url('../assets/image/l3.png');
  // background-position: 0 -107px;
  // background-size: 188.5px 147px;
}
.b4 {
  background-image: url('../assets/image/l4.png');
  // background-position: -45px -62px;
  // background-size: 188.5px 147px;
}
.b5 {
  background-image: url('../assets/image/l5.png');
  // background-position: -107px -42px;
  // background-size: 188.5px 147px;
}
.b6 {
  background-image: url('../assets/image/l6.png');
  // background-position: -107px 0;
  // background-size: 188.5px 147px;
}
.bl1 {
  animation: bubble_1 var(--bubble_time) linear 1 forwards,
    bubble_big_1 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl2 {
  animation: bubble_2 var(--bubble_time) linear 1 forwards,
    bubble_big_2 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl3 {
  animation: bubble_3 var(--bubble_time) linear 1 forwards,
    bubble_big_1 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl4 {
  animation: bubble_4 var(--bubble_time) linear 1 forwards,
    bubble_big_2 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl5 {
  animation: bubble_5 var(--bubble_time) linear 1 forwards,
    bubble_big_1 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl6 {
  animation: bubble_6 var(--bubble_time) linear 1 forwards,
    bubble_big_3 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl7 {
  animation: bubble_7 var(--bubble_time) linear 1 forwards,
    bubble_big_1 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl8 {
  animation: bubble_8 var(--bubble_time) linear 1 forwards,
    bubble_big_3 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl9 {
  animation: bubble_9 var(--bubble_time) linear 1 forwards,
    bubble_big_2 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl10 {
  animation: bubble_10 var(--bubble_time) linear 1 forwards,
    bubble_big_1 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
.bl11 {
  animation: bubble_11 var(--bubble_time) linear 1 forwards,
    bubble_big_2 var(--bubble_scale) linear 1 forwards,
    bubble_y var(--bubble_time) linear 1 forwards;
}
@keyframes bubble_11 {
  0% {
  }
  25% {
    margin-left: -10px;
  }
  50% {
    margin-left: -10px;
  }
  100% {
    margin-left: -18px;
  }
}
@keyframes bubble_10 {
  0% {
  }
  25% {
    margin-left: -20px;
  }
  50% {
    margin-left: -20px;
  }
  100% {
    margin-left: -20px;
  }
}
@keyframes bubble_9 {
  0% {
  }
  25% {
    margin-left: 10px;
  }
  50% {
    margin-left: 10px;
  }
  100% {
    margin-left: 10px;
  }
}
@keyframes bubble_8 {
  0% {
  }
  25% {
    margin-left: 20px;
  }
  50% {
    margin-left: 20px;
  }
  100% {
    margin-left: 20px;
  }
}
@keyframes bubble_7 {
  0% {
  }
  25% {
    margin-left: 3px;
  }
  50% {
    margin-left: 1px;
  }
  75% {
    margin-left: 2px;
  }
  100% {
    margin-left: 3px;
  }
}
@keyframes bubble_6 {
  0% {
  }
  25% {
    margin-left: -3px;
  }
  50% {
    margin-left: -1px;
  }
  75% {
    margin-left: -2px;
  }
  100% {
    margin-left: -3px;
  }
}
@keyframes bubble_5 {
  0% {
  }
  25% {
    margin-left: 5px;
  }
  50% {
    margin-left: -5px;
  }
  75% {
    margin-left: -10px;
  }
  100% {
    margin-left: -20px;
  }
}
@keyframes bubble_4 {
  0% {
  }
  25% {
    margin-left: -5px;
  }
  50% {
    margin-left: -5px;
  }
  75% {
    margin-left: 20px;
  }
  100% {
    margin-left: 10px;
  }
}
@keyframes bubble_3 {
  0% {
  }
  25% {
    margin-left: -20px;
  }
  50% {
    margin-left: 10px;
  }
  75% {
    margin-left: 20px;
  }
  100% {
    margin-left: -10px;
  }
}
@keyframes bubble_2 {
  0% {
  }
  25% {
    margin-left: 20px;
  }
  50% {
    margin-left: 25px;
  }
  75% {
    margin-left: 10px;
  }
  100% {
    margin-left: 5px;
  }
}
@keyframes bubble_1 {
  0% {
  }
  25% {
    margin-left: -8px;
  }
  50% {
    margin-left: 8px;
  }
  75% {
    margin-left: -15px;
  }
  100% {
    margin-left: 15px;
  }
}
@keyframes bubble_big_1 {
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes bubble_big_2 {
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes bubble_big_3 {
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes bubble_y {
  0% {
    margin-bottom: 0;
  }
  10% {
    margin-bottom: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    margin-bottom: 200px;
    opacity: 0;
  }
}

</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
